<template>
  <v-container fluid>
    <v-col cols="12">
      <v-row class="pl-16">
        <v-col cols="5" class="pl-14">
          <v-row class="mt-5">
            <Menu></Menu>
            <div class="text-h4 font-weight-bold">Número de exames liberados</div>
          </v-row>
          <v-row>
            <div class="light-blue-rectangle mt-3"></div>
          </v-row>
        </v-col>

        <v-col cols="7" class="justify-space-around pl-16">
          <v-row>
            <v-col cols="8" class="mt-10 pl-16 justify-end">
              <date-picker style="max-width: 160px" v-model="params.dataInicio" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data de início"
                           lang="pt-br"></date-picker>
              <date-picker style="max-width: 160px" v-model="params.dataFinal" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data final"
                           lang="pt-br"></date-picker>
            </v-col>
            <v-col cols="4">
              <v-list-item class="mt-6 justify-end pr-16 mr-16 pt-2">
                <div class="text-center">
                  <v-dialog v-model="modal"  transition="dialog-top-transition" max-width="800">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="modal = true" v-bind="attrs" v-on="on">
                        <v-icon title="Filtro">mdi mdi-filter</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        Filtros
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row class="justify-space-around pt-2">
                          <v-col cols="5">
                            <v-select v-model="cnpjsSelecionados" :items="cnpjs" item-text="cnpj" label="Laboratórios" multiple chips hint="Selecione os laboratórios que serão exibidos" persistent-hint></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete v-model="params.tipoDeExameFiltro" clearable chips label="Tipo de exame" :items="tiposDeExamePorCNPJSemFiltro.tiposExamePorCNPJ.map(obj => obj.tipoExame)" multiple></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="modal = false">
                          Pesquisar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-9 pl-16">
        <v-col v-for="card in cards" :key="card.title" cols="12" md="6">
          <div class="pl-8 pr-8">
            <h3 class="chart-title">{{ card.title }}</h3>
            <div :id="card.id"></div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import api from "@/plugins/api";
import {GoogleCharts} from "google-charts";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import Menu from "@/components/Menu.vue";

export default {
  name: "numeroDeExamesLiberados",
  components: {Menu, DatePicker},

  data() {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 6);
    let formattedDate = currentDate.toLocaleDateString();
    return {
      params: {
        cnpj: [],
        dataInicio: formattedDate,
        dataFinal: new Date().toLocaleDateString('pt-br'),
        tipoDeExameFiltro: [],
      },
      tiposDeExamePorCNPJSemFiltro: [],
      qtdExames: [],
      tipoExame: [],
      cnpjs: [],
      cnpjsSelecionados: [],
      primeiroCNPj: null,
      cards: [
        {title: "Quantidade de exames por tipo de exame", id: "qtdDeExamesPorTipoExame"},
        {title: "Percentual por tipo de exame", id: "percentualPorTipoExame"},
        {title: "Quantidade de exames mensal por tipo de exame", id: "quantidadeDeExamesMensalPorTipoExame"},
        {title: "Quantidade de exames mensal", id: "quantidadeDeExamesMensal"},
      ],
      modal: false,
    }

  },

  created() {
    this.listaDeLaboratorios();
    addEventListener("resize", () => {
      this.larguraTable = document.getElementById('quantidadeDeExamesMensal').offsetWidth;
      this.drawChartQtdDeExamesPorTipoExame();
      this.drawChartPercentualDeExamesPorTipoDeExame();
      this.drawChartQuantidadeDeExamesMensal();
      this.drawChartQuantidadeDeExamesMensalPorTipoDeExame();

    });
  },

  mounted() {
    this.larguraTable = document.getElementById('quantidadeDeExamesMensal').offsetWidth;
  },


  watch: {
    'params.dataInicio': 'loadData',
    'params.dataFinal': 'loadData',
    cnpjsSelecionados: {
      handler(newValue) {
        // Verificar se o valor atual é diferente do valor armazenado
        const storedCNPJs = JSON.parse(localStorage.getItem('selectedCNPJs'));
        if (JSON.stringify(newValue) !== JSON.stringify(storedCNPJs)) {
          localStorage.setItem('selectedCNPJs', JSON.stringify(newValue));
          this.loadData();
        }
      },
    },
    'params.tipoDeExameFiltro': 'loadData',
  },

  methods: {
    listaDeLaboratorios(){
      api.post('api/autenticacao/listaDeLaboratorios', this.params).then(response => {
        this.cnpjs = response.data;
        this.primeiroCNPj = this.cnpjs[0].cnpj
        this.loadData();
      });
    },

    loadData() {
      const selectedCNPJs = localStorage.getItem('selectedCNPJs');

      if (!selectedCNPJs) {
        this.params.cnpj = [this.primeiroCNPj];
        this.cnpjsSelecionados = this.params.cnpj;
      } else {
        this.params.cnpj = JSON.parse(selectedCNPJs);
        this.cnpjsSelecionados = this.params.cnpj
      }

      api.post('api/evolucaoDoNumeroDeExamesLiberados/tiposDeExameSemFiltro', this.params).then(response => {
        this.tiposDeExamePorCNPJSemFiltro = response.data;
      });

      api.post('api/evolucaoDoNumeroDeExamesLiberados/qtdDeExamesPorTipoExame', this.params).then(response => {
        this.qtdExamesLiberadosPorTipo = response.data;
          this.drawChartQtdDeExamesPorTipoExame();
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      });

      api.post('api/evolucaoDoNumeroDeExamesLiberados/percentualPorTipoDeExame', this.params).then(response => {
        this.percentualExamesPorTipoExame = response.data;
        this.drawChartPercentualDeExamesPorTipoDeExame();
      })

      api.post('api/evolucaoDoNumeroDeExamesLiberados/tiposExamePorCNPJ', this.params).then(response => {
        this.tiposDeExame = response.data;
        api.post('api/evolucaoDoNumeroDeExamesLiberados/quantidadeDeExamesMensalPorTipoDeExame', this.params).then(response => {
          this.quantidadeDeExamesMensalPorTipo = response.data;
            this.drawChartQuantidadeDeExamesMensal();
            this.drawChartQuantidadeDeExamesMensalPorTipoDeExame();
        });
      });
    },

    drawChartQtdDeExamesPorTipoExame() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Tipo de Exame');
        data.addColumn('number', 'Quantidade de exames');
        data.addColumn({role: "style"})

        const cores = [
          "#2196f3", "#ff9800", "#673ab7", "#ff5722", "#b2ebf2", "#4caf50", "#5c6bc0", "#00bcd4", "#009688", "#f44336",
          "#9c27b0", "#e91e63", "#ffd54f", "#ffecb3", "#ffcc80", "#80deea", "#4dd0e1", "#c5cae9", "#7986cb",
        ];
        let indiceCor = 0;

        Object.keys(this.qtdExamesLiberadosPorTipo).forEach(tipoExame => {
          const item = this.qtdExamesLiberadosPorTipo[tipoExame];
          item.forEach(item => {
            const cor = cores[indiceCor % cores.length];
            data.addRow([item.tipoExame, item.qtdExames, cor]);
            indiceCor++
          })
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: {color: '#607d8b', count: 5},
            textStyle: {color: 'white', fontName: 'Roboto', fontSize: '12', bold: true},
          },
          vAxis: {
            minValue: 0,
            gridlines: {color: '#37474f', count: 4},
            baselineColor: 'transparent',
            textStyle: {color: 'white', fontName: 'Roboto', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color: 'white', fontSize: '14', bold: true}},
          colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39"],
          areaOpacity: 0.24,
          lineWidth: 1,
          chartArea: {
            backgroundColor: "transparent",
            width: '67%',
            height: '80%'
          },
          height:(this.larguraTable / 3), // example height, to make the demo charts equal size
          width: this.larguraTable,
          bar: {groupWidth: "20"},
          colorAxis: {colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4"]},
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions',
          explorer: {},
        };

        var chart = new GoogleCharts.api.visualization.BarChart(
            document.getElementById('qtdDeExamesPorTipoExame')
        );
        chart.draw(data, options);
      });
    },

    drawChartPercentualDeExamesPorTipoDeExame() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Tipo de Exame');
        data.addColumn('number', 'Percentual de Exames');

        this.percentualExamesPorTipoExame.forEach(linha => {
          let rowData = [linha[0]];
          for (let i = 1; i < linha.length; i++) {
            rowData.push(linha[i]);
          }
          data.addRows([rowData]);
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: {color: '#607d8b', count: 5},
            textStyle: {color: 'white', fontName: 'Roboto', fontSize: '12', bold: true},
          },
          vAxis: {
            minValue: 0,
            gridlines: {color: '#37474f', count: 4},
            baselineColor: 'transparent',
            textStyle: {color: 'white', fontName: 'Roboto', fontSize: '12', bold: true}
          },
          legend: {position: 'left', alignment: 'center', textStyle: {color: 'white', fontSize: '14', bold: true}},
          areaOpacity: 0.24,
          lineWidth: 1,
          chartArea: {
            backgroundColor: "transparent",
            width: '67%',
            height: '80%'
          },
          height:(this.larguraTable / 3), // example height, to make the demo charts equal size
          width: this.larguraTable,
          bar: {groupWidth: "20"},
          colorAxis: {colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4"]},
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions',
        };

        var chart = new GoogleCharts.api.visualization.PieChart(
            document.getElementById('percentualPorTipoExame')
        );
        chart.draw(data, options);
      });
    },

    drawChartQuantidadeDeExamesMensal() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Mês');

        // Itera sobre cada patologista na array de objetos
        Object.keys(this.tiposDeExame).forEach(tipoExame => {
          const nomesTipoExame = this.tiposDeExame[tipoExame].map(obj => obj.tipoExame);
          nomesTipoExame.forEach(nome => {
            data.addColumn('number', nome);
          });
        });

        // Itera sobre a lista de mediaDiasPatologistaMensal
        this.quantidadeDeExamesMensalPorTipo.forEach(linha => {
          let rowData = [linha[0]];

          for (let i = 1; i < linha.length; i++) {
            rowData.push(linha[i]);
          }

          data.addRows([rowData]);
        });

        const options = {
          chart: {
            title: 'Quantidade de exames mensal',
            subtitle: 'Estatísticas por mês',
            hAxis: -1,
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          hAxis: {
            titleTextStyle: {color: '#b0bec5'},
            gridlines: {count: 0},
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color: '#37474f', count: 4},
            baselineColor: 'transparent',
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color: 'white', fontSize: '12'}},
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height:(this.larguraTable / 3), // example height, to make the demo charts equal size
          width: this.larguraTable,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle: {color: '#607d8b'},
          pieHole: 0.9,
          bar: {groupWidth: "40"},
          colorAxis: {colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4"]},
          backgroundColor: "#32373d",
          datalessRegionColor: '#37474f',
          displayMode: 'regions',

          isStacked: true
        };

        const chart = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('quantidadeDeExamesMensal'));
        chart.draw(data, options);
      });
    },

    drawChartQuantidadeDeExamesMensalPorTipoDeExame() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Mês');

        // Itera sobre cada patologista na array de objetos
        Object.keys(this.tiposDeExame).forEach(tipoExame => {
          const nomesTipoExame = this.tiposDeExame[tipoExame].map(obj => obj.tipoExame);
          nomesTipoExame.forEach(nome => {
            data.addColumn('number', nome);
          });
        });

        // Itera sobre a lista de mediaDiasPatologistaMensal
        this.quantidadeDeExamesMensalPorTipo.forEach(linha => {
          let rowData = [linha[0]];

          for (let i = 1; i < linha.length; i++) {
            rowData.push(linha[i]);
          }

          data.addRows([rowData]);
        });

        const options = {
          chart: {
            title: 'Quantidade de exames mensal',
            subtitle: 'Estatísticas por mês',
            hAxis: -1,
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          hAxis: {
            titleTextStyle: {color: '#b0bec5'},
            gridlines: {count: 0},
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color: '#37474f', count: 4},
            baselineColor: 'transparent',
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color: 'white', fontSize: '12'}},
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height:(this.larguraTable / 3), // example height, to make the demo charts equal size
          width: this.larguraTable,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle: {color: '#607d8b'},
          pieHole: 0.9,
          bar: {groupWidth: "40"},
          colorAxis: {colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4"]},
          backgroundColor: "#32373d",
          datalessRegionColor: '#37474f',
          displayMode: 'regions',
          explorer: {},
          curveType: 'function',
        };

        const chart = new GoogleCharts.api.visualization.LineChart(document.getElementById('quantidadeDeExamesMensalPorTipoExame'));
        chart.draw(data, options);
      });
    }

  }
}
</script>

<style scoped>
.chart-title {
  color: #fff; /* Cor branca */
  font-weight: bold; /* Negrito */
}

.light-blue-rectangle {
  width: 70vh;
  height: 10px;
  background-color: #2196f3;
}
</style>